.bottom-tab-bar-container {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #1f1f1f;
    z-index: 999;
    height: 60px;
    display: flex;
    align-items: center;
    flex: 1;
}

.tab-bar-content {
    display: flex !important;
    flex-direction: row !important;
    max-width: 740px !important;
}

@media only screen and (max-width: 992px) {
    .tab-bar-content {
        flex: 1;
    }
}

.tab-bar-item-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.bottom-tab-bar-style {
    width: 30px;
    height: 30px;
}
