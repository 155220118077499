.login-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-content {
    padding: 20px 20px;
    min-width: 250px;
    width: 100%;
    max-width: 380px;
    position: relative;
}

.logo-login {
    width: 300px;
    height: 90px;
    margin-left: 20px;
}

.login-background {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.login-input {
    background-color: #000 !important;
    border: 3px solid #c9986b !important;
    border-radius: 10px !important;
    color: rgba(224, 214, 179, 0.5) !important;
    padding: 18px 25px !important;
    font-family: "Kanit" !important;
}

.login-input:focus {
    outline: none;
}

::-ms-reveal {
    filter: invert(100%);
}

.show-password {
    color: #fff !important;
    display: flex !important;
    align-items: center !important;
}

.topic-login {
    color: rgba(201, 155, 112, 1);
    font-size: 24px;
    margin: 20px 0px 30px;
    position: relative;
    display: flex;
    justify-content: center;
}

.button-login {
    background-color: rgba(90, 58, 45, 1) !important;
    margin-bottom: 15px !important;
}

.button-register {
    background-color: #8b7451 !important;
    margin-bottom: 25px !important;
}

.register-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-container-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.register-content {
    padding: 20px 35px;
    min-width: 250px;
    width: 100%;
    max-width: 380px;
    position: relative;
}

.topic-register {
    color: rgba(201, 155, 112, 1);
    font-size: 24px;
    text-align: center;
    margin: 10px 0px 10px;
}

.error_message {
    color: #fff;
    font-size: 16px;
}
