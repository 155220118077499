@media only screen and (min-width: 992px) {
    .ui.container {
        width: 740px;
    }
}

.transparent_segment {
    box-shadow: unset;
    border: 0px;
    margin-top: 35;
    margin-bottom: 18;
    background: transparent;
}

.ui.dropdown .menu {
    background-color: #bb702c !important;
}

.ui.dropdown .menu > .item {
    background-color: rgba(187, 112, 44, 0.2) !important;
}

.ui.loading:before {
    background-color: transparent !important;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .ui.grid > .row > [class*="six wide mobile"].column,
    .ui.grid > .column.row > [class*="six wide mobile"].column,
    .ui.grid > [class*="six wide mobile"].column,
    .ui.column.grid > [class*="six wide mobile"].column {
        width: 50% !important;
    }
}
