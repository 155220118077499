.ui.button {
    width: 100%;
    border-radius: 20px;
    font-size: 18px;
    font-family: "Kanit";
    background-color: rgba(90, 58, 45, 1);
    font-weight: 300;
    color: #fff;
}

.header-custom {
    color: #fff;
    font-size: 30px;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-custom {
    background-color: rgba(187, 112, 44, 0.2) !important;
    border: 3px solid rgba(187, 112, 44, 1) !important;
    border-radius: 10px !important;
    color: rgba(255, 255, 255, 0.5) !important;
    padding: 15px 25px !important;
    font-family: "Kanit" !important;
    text-align: center;
}

.input-custom:focus {
    outline: none;
}

.profile-button {
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 20px;
    max-width: 350px;
    position: relative;
}

.select-date {
    background-color: rgba(187, 112, 44, 0.2) !important;
    border: 3px solid rgba(187, 112, 44, 1) !important;
    border-radius: 10px !important;
    color: rgba(255, 255, 255, 0.5) !important;
    padding: 15px 25px !important;
    font-family: "Kanit" !important;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
}

.react-datepicker-wrapper {
    width: 100%;
    max-width: 350px;
}

.react-datepicker {
    max-width: 380px !important;
    width: 300px !important;
}

.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 2.5rem !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    background: rgba(187, 112, 44, 1);
}

.react-datepicker__month {
    background: #e2b183;
    margin: 0px !important;
    padding: 0.4rem !important;
}

.delete_profile_button {
    border-radius: 5px !important;
    background-color: rgba(171, 141, 96, 0.5) !important;
    border: 1px solid #ab8d60 !important;
    color: #fff !important;
    margin: 5px !important;
    width: 40px !important;
    padding: 0px 5px !important;
}

.ui.modal > .close {
    color: #fff !important;
}
