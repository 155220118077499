@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap");
body {
    font: 1em "Kanit" !important;
    font-weight: 300 !important;
    font-style: normal !important;
    width: 100%;
    height: 100%;
    margin: 0;
    background-image: url("../images/auth/background_body2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #1f1f1f;
    background-repeat: no-repeat;
    background-attachment: fixed;
    touch-action: manipulation !important;
}

h1,
h2,
h3,
h4,
h5,
label {
    font: "Kanit" !important;
    font-weight: 300 !important;
    font-style: normal !important;
}

label {
    color: rgba(201, 155, 112, 1) !important;
}

@media only screen and (max-width: 490px) {
    body {
        background-size: initial !important;
    }
}

@import "auth.css";
@import "component.css";
@import "custom.css";
@import "bottom-tab-bar.css";
